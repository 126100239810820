// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore, collection, where, orderBy, limit, query, doc, getDocs, setDoc, addDoc, deleteDoc } from 'firebase/firestore/lite';
import { getAuth, onAuthStateChanged, signInWithEmailAndPassword, signInWithCustomToken } from 'firebase/auth';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCBdbZhahcXxQ8olzmtd8owoL38SQSIRdo",
  authDomain: "memorycard-3ccdb.firebaseapp.com",
  projectId: "memorycard-3ccdb",
  storageBucket: "memorycard-3ccdb.appspot.com",
  messagingSenderId: "170478429374",
  appId: "1:170478429374:web:02e7153add5a94afe7eaef",
  measurementId: "G-CQ0BW95JR9"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const firestore = {
  db: getFirestore(app),
  collection,
  query,
  where,
  orderBy,
  limit,
  doc,
  getDocs,
  setDoc,
  addDoc,
  deleteDoc,
  auth: getAuth(),
  onAuthStateChanged,
  signInWithEmailAndPassword,
  signInWithCustomToken
}

export default firestore