<template>
  <div v-show="modelValue" class="loading-wrapper d-flex align-center justify-center">
    <sm-loading :size="12" color="primary"/>
  </div>
</template>

<script>
import SmLoading from './Sm-Loading.vue';
export default {
    props: {
        modelValue: Boolean
    },
    components: {
        SmLoading
    },
    computed: {
        routerLoading: {
            get() {
                return this.modelValue;
            },
            set(v) {
                this.$emit('update:modelValue', v);
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.loading-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 100;
}
</style>