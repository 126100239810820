<template>
  <div class="d-flex-row">
    <input
      :placeholder="placeholder"
      :type="type"
      :value="modelValue"
      :readonly="readonly"
      @input="$emit('update:modelValue', $event.target.value)"
      :class="{
        'no-outline': nooutline,
        'round': !tile,
        [`${backgroundcolor}--bg`]: backgroundcolor==undefined ? false : true,
        [`${bordercolor}--border`]: bordercolor==undefined ? false : true,
      }"
      :style="{ width: width, height: height, fontSize: 'inherit', fontWeight: 'inherit', color: 'inherit',
      borderWidth: `${borderwidth? borderwidth : 1}px`}"
    />
    <div class="text-9 text-bold">
      <slot name="append" />
    </div>
  </div>
</template>

<script>
/*
props
  placeholder(str): プレースホルダ
  type(str): タイプ
  readonly(bool):
  width, height(str):
  tile: 角丸無効
  nooutline(bool): ボーダー
  backgroundcolor(str[color]): 背景色
  bordercolor(str[color]): 枠線色
*/
export default {
  props: {
    modelValue: String,
    placeholder: String,
    type: String,
    readonly: Boolean,
    width: String,
    height: String,
    tile: Boolean,
    nooutline: Boolean,
    backgroundcolor: String,
    bordercolor: String,
    borderwidth: Number
  }
};
</script>

<style lang="scss" scoped>
.no-outline {
  border: none;
  &:focus {
    outline: none;
  }
}
.round {
  border-radius: 20px;
}
</style>
