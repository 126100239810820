// css
import "./scss/Sm-style-default.scss";
import "./scss/Sm-style-spacing.scss";
import "./scss/Sm-style-text.scss";
import "./scss/Sm-style-display.scss";

// components
import SmIcon from "./Sm-Icon";
import SmButton from "./Sm-Button";
import SmInput from "./Sm-Input";
import SmTextarea from "./Sm-Textarea";
import SmCard from "./Sm-Card";
import SmHeader from "./Sm-Header";
import SmFooter from "./Sm-Footer";
import SmDivider from "./Sm-Divider";
import SmSwitch from "./Sm-Switch";
import SmTab from "./Sm-Tab";
import SmDialog from "./Sm-Dialog";
import SmLoading from "./Sm-Loading";
import SmLoadinWrapper from "./Sm-LoadingWrapper"

export function registerComponent(app) {
    app.component("sm-icon", SmIcon);
    app.component("sm-button", SmButton);
    app.component("sm-input", SmInput);
    app.component("sm-textarea", SmTextarea);
    app.component("sm-card", SmCard);
    app.component("sm-header", SmHeader);
    app.component("sm-footer", SmFooter);
    app.component("sm-divider", SmDivider);
    app.component("sm-switch", SmSwitch);
    app.component("sm-tab", SmTab);
    app.component("sm-dialog", SmDialog);
    app.component("sm-loading", SmLoading);    
    app.component("sm-loading-wrapper", SmLoadinWrapper);
}

