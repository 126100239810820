<template>
    <div>
        <div v-for="(item, i) in items" :key="i" @click.stop="setCategory(item)">
            <sm-card tile flat class="card pa-8 mt-3 mb-3 d-flex" :class="{'selected': isSelected(item)}" :backgroundcolor="getBackgroundColor(item)">
                <div class="w-100">
                    {{ item.name }}
                </div>
                <div class="text-align-right">
                    <!--loading-->
                    <div v-show="item.state=='loading'">
                        <sm-loading :size="1"/>
                    </div>
                    <!--done-->
                    <div v-show="item.state=='done'" @click="categoryMenuOnClick(item, $event)">
                        <sm-icon icon="fas fa-ellipsis-v"/>
                    </div>
                </div>
            </sm-card>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            showRenameDialog: false
        }
    },
    computed: {
        items() {
            return this.$store.state.category.items;
        }
    },
    methods: {
        async setCategory(item) {
            this.$store.commit('category/setCategory', { value: item.id });
            this.$store.dispatch('card/fetch', { force: true });
            this.$emit('setCategory');
        },
        isSelected(item) {
            return this.$store.state.category.selectedId == item.id;
        },
        getBackgroundColor(item) {
            return this.$store.state.category.selectedId == item.id ? 'selected' : undefined;
        },
        categoryMenuOnClick(item, e) {
            e.stopPropagation();
            this.$emit('categoryMenuOnClick', item, e)
        },
    },
}
</script>

<style lang="scss" scoped>
@import "./sm/scss/Sm-style-variables.scss";
.card {
    cursor: pointer;
    transition: all 0.3s ease;
}
.card:not(.selected):hover {
    background-color: get-color(focusback);
}
</style>