<template>
  <div>
    <AppHeader/>
    <SideMenu/>
    <CardList/>
  </div>
</template>

<script>
import AppHeader from '../components/AppHeader'
import CardList from '../components/CardList'
import SideMenu from '../components/SideMenu'
export default {
  computed:{
    isMenuOpen() {
      return this.$store.state.menu.isOpen;
    }
  },
  components: {
    AppHeader,
    CardList,
    SideMenu
  },
};
</script>
