import { createStore } from 'vuex'
import fs from '@/firebase';

const getUserId = () => {
  if (!AuthModule.state.user) return null;
  return AuthModule.state.user.uid;
};

const getCategoryId = () => {
  return CategoryModule.state.selectedId;
}

/*********************************************************************
 * Auth
 *********************************************************************/
const AuthModule = {
  namespaced: true,
  state: {
    user: null
  },
  mutations: {
    user(state, payload) {
      state.user = payload;
    }
  },
  actions: {
    async signin({state}, arg) {
      // デバッグ用
      //arg.email='takahiro00095@gmail.com';
      //arg.password='gb6781009';
      // テスト用
      // koyoru12@yahoo.co.jp : koyoru12
      try {
        const userCredential = await fs.signInWithEmailAndPassword(fs.auth, arg.email, arg.password);
        const user = userCredential.user;
        state.user = user;
        return true;
      } catch (error) {
        console.error(error);
        return false;
      }
    },
    auth() {
      return new Promise((resolve) => {
        let unsubscribe = fs.auth.onAuthStateChanged((user) => {
          // user オブジェクトを resolve
          $store.commit('auth/user', user);
          resolve(user);
    
          // 登録解除
          unsubscribe();
        });
      });
    }
  }
}


/*********************************************************************
 * Menu
 *********************************************************************/
const MenuModule = {
  namespaced: true,
  state: {
    isOpen: false
  },
  mutations: {
    toggle(state) {
      state.isOpen = !state.isOpen;
    }
  }
}

/*********************************************************************
 * Category
 *********************************************************************/
const CategoryModule = {
  namespaced: true,
  state: {
    selectedId: null,
    collectionName: 'category',
    items: [
      /*
      {
        id: 0,
        category_name: '',
        state: '' // done / loading / failed
      }
      */
    ],
  },
  mutations: {
    setCategory(state, payload) {
      if (payload) {
        state.selectedId = payload.value;
      } else {
        if (state.items.length > 0) {
          state.selectedId = state.items[0].id;
        } else {
          state.selectedId = null;
        }
      }
    },
  },
  actions: {
    async fetch({state}, options) {
      const force = options && options.force;
      if (state.items.length > 0 && !force) return state.items;

      const col = fs.collection(fs.db, 'category');
      const q = fs.query(col, fs.where('user_id', '==', getUserId()), fs.orderBy('display_index'));
      const docs = await fs.getDocs(q);
      let items = [];
      docs.docs.forEach(doc => {
        let data = doc.data();
        items.push({
          id: doc.id,
          user_id: data.user_id,
          name: data.name,
          display_index: data.display_index,
          state: 'done'   // done / loading / failed
          })
      })
      state.items = items;
      if (state.items.length > 0 && state.selectedId == null) state.selectedId = state.items[0].id;
    },
    async update({state}, doc) {
      doc.state='loading';

      fs.setDoc(fs.doc(fs.db, state.collectionName, doc.id), {
        user_id: doc.user_id,
        name: doc.name,
        display_index: doc.display_index
      })
      .then(() => {
        doc.state='done';
      })
      .catch(e => {
        doc.state='failed';
      });
    },
    async insert({state, dispatch}, doc) {
      const col = fs.collection(fs.db, state.collectionName);
      // display_indexが設定されていない場合は設定
      const di = doc.display_index == undefined ? await dispatch('getLastDisplayIndex') + 100 : doc.display_index;
      let localDoc = {
        user_id: getUserId(),
        name: doc.name,
        state: 'loading',
        display_index: di
      };
      state.items.push(localDoc);
      fs.addDoc(col, localDoc)
        .then((dbDoc) => {
          localDoc.id = dbDoc.id;
          localDoc.state = 'done';
        })
        .catch(e => {
          localDoc.state = 'failed';
        });
    },
    // ひとつ上下に移動する
    // payload: { doc:, direction: }
    // direction = up / down
    async moveDisplayIndex({state, dispatch}, payload) {
      let target = null;
      let doc = payload.doc;
      let direction = payload.direction;
      for (let i in state.items) {
        if (state.items[i].id == doc.id) {
          if (direction == 'up') {
            if (Number(i) > 0) {
              target = state.items[Number(i) - 1];
            }
          } else if (direction == 'down') {
              target = state.items[Number(i) + 1];
          }
          break;
        }
      }
      if (!target) return;
      let di = doc.display_index;
      doc.display_index = target.display_index;
      target.display_index = di;
      await dispatch('sortLocalItems');
      dispatch('update', doc);
      dispatch('update', target);
    },
    async remove({ state }, id) {
      let doc = null;
      for (let i in state.items) {
        if (state.items[i].id == id) {
          doc = state.items[i];          
          doc.state = 'loading';
        }
      };
      fs.deleteDoc(fs.doc(fs.db, state.collectionName, id))
      .then(() => {
        for (let i in state.items) {
          if (state.items[i].id == id) {
            state.items.splice(i, 1);
          }
        };
      })
      .catch(e => {
        doc.state = 'failed';
      });
    },
    // itemsをdisplayIndex順に並び替える
    sortLocalItems({state}) {
      state.items.sort((a, b) => {
        return a.display_index - b.display_index;
      });
    },
    // 最後尾のDisplayIndexを取得する
    getLastDisplayIndex({state}) {
      let index = 0;
      state.items.forEach(item => {
        index = item.display_index > index ? item.display_index : index;
      });
      return index;
    },
    // 特定のdocumentの後ろに追加する場合のIndexを取得する
    // appentAfter:afterに指定したdocumentの後ろに追加する
    getInsertDisplayIndex({state}, appendAfter) {
      let appendAfter2 = null;
      for (let i in state.items) {
        if (state.items[i].id == appendAfter.id) {
          appendAfter2 = state.items[Number(i) + 1];
          break;
        }
      }
      if (appendAfter2 == null) {
        // 最後尾のとき
        return appendAfter.display_index + 100;
      } else {
        // 2つのdocumentのdisplay_indexの平均
        return (appendAfter.display_index + appendAfter2.display_index) / 2;
      }
    }
  }
}

/*********************************************************************
 * Card
 *********************************************************************/
const CardModule = {
  namespaced: true,
  state: {
    collectionName: 'card',
    items: [
    /*
      {
        id: 0,
        category_id: 0,
        front: '',
        back: '',
        timstamp: ,
        display_index: ,
        state: ,
        }
      }
    */
    ],
    displayItems: [],
    displayOnlyCheckItems: false
  },
  mutations: {
    updateItems(state, payload) {
      state.items = payload;
    },
  },
  getters: {
    displayItems(state) {
      return state.displayItems;
    }
  },
  actions: {
    async fetch({state}, options) {
      const force = options && options.force;
      if (state.items.length > 0 && !force) return state.items;

      const col = fs.collection(fs.db, state.collectionName);
      let qArray = [];
      qArray.push(fs.where('user_id', '==', getUserId()));
      qArray.push(fs.where('category_id', '==', getCategoryId()));
      qArray.push(fs.orderBy('display_index'));
      const query = fs.query(col, ...qArray);
      const docs = await fs.getDocs(query);
      let items = [];
      let i = 100;
      docs.docs.forEach(doc => {
        let data = doc.data();
        items.push({
          id: doc.id,   // idは自動で付番されているため、fieldに含める必要ない
          user_id: data.user_id,
          category_id: data.category_id,
          front: data.front,
          back: data.back,
          check: data.check,
          bookmark: data.bookmark,
          timestamp: data.timestamp,
          display_index: data.display_index,
          state: 'done'   // done / loading / failed
        })
      })
      $store.commit('card/updateItems', items);
      $store.dispatch('card/updateDisplayItems');
      return state.items;
    },
    updateDisplayItems({state}, toggle) {
      if (toggle !== undefined) {
        state.displayOnlyCheckItems = toggle;
      }
      state.displayItems = null;
      let items = [];
      for (let i in state.items) {
        if (state.displayOnlyCheckItems) {
          if (state.items[i].check) {
            items.push(state.items[i]);
          }
        } else {
          items.push(state.items[i]);
        }
      }
      state.displayItems = items;
    },
    async insert({state, dispatch}, doc) {
      const col = fs.collection(fs.db, state.collectionName);
      // display_indexが設定されていない場合は設定
      console.log(doc.display_index)
      const di = doc.display_index == null ? await dispatch('getLastDisplayIndex') + 100 : doc.display_index;
      console.log(di)
      let localDoc = {
        user_id: getUserId(),
        category_id: getCategoryId(),
        front: doc.front,
        back: doc.back,
        check: false,
        bookmark: false,
        display_index: di,
        timestamp: Date.now(),
        state: 'loading'
      };
      state.items.push(localDoc);
      // どうも配列の要素数で変更検知されるかどうかが決まっている様子（プロパティの内容は検知しない）
      //$store.dispatch('card/updateDisplayItems');
      await dispatch('sortLocalItems');
      fs.addDoc(col, localDoc)
        .then((dbDoc) => {
          localDoc.id = dbDoc.id;
          localDoc.state = 'done';
          // そのままだと変更検知しないのでやむなく対応
          dispatch('updateDisplayItems');
          for (let i in state.displayItems) {
            if (state.displayItems[i].timestamp == localDoc.timestamp) {
              let localDoc2 = JSON.parse(JSON.stringify(localDoc));
              state.displayItems[i] = localDoc2;    
            }
          }
        })
        .catch(e => {
          localDoc.state = 'failed';
          dispatch('updateDisplayItems');
          for (let i in state.displayItems) {
            if (state.displayItems[i].timestamp == localDoc.timestamp) {
              let localDoc2 = JSON.parse(JSON.stringify(localDoc));
              state.displayItems[i] = localDoc2;    
            }
          }
        });
    },
    async update({state}, doc) {
      doc.state='loading';
      fs.setDoc(fs.doc(fs.db, state.collectionName, doc.id), {
        user_id: doc.user_id,
        category_id: doc.category_id,
        front: doc.front,
        back: doc.back,
        check: doc.check?true:false,
        bookmark: doc.bookmark?true:false,
        display_index: doc.display_index,
        timestamp: doc.timestamp
      })
      .then(() => {
        doc.state='done';
      })
      .catch(e => {
        doc.state='failed';
      });
    },
    // ひとつ上下に移動する
    // payload: { doc:, direction: }
    // direction = up / down
    async moveDisplayIndex({state, dispatch}, payload) {
      let target = null;
      let doc = payload.doc;
      let direction = payload.direction;
      for (let i in state.items) {
        if (state.items[i].id == doc.id) {
          if (direction == 'up') {
            if (Number(i) > 0) {
              target = state.items[Number(i) - 1];
            }
          } else if (direction == 'down') {
              target = state.items[Number(i) + 1];
          }
          break;
        }
      }
      if (!target) return;
      let di = doc.display_index;
      doc.display_index = target.display_index;
      target.display_index = di;
      await dispatch('sortLocalItems');
      dispatch('update', doc);
      dispatch('update', target);
    },
    async remove({ state }, id) {
      let doc = null;
      for (let i in state.items) {
        if (state.items[i].id == id) {
          doc = state.items[i];          
          doc.state = 'loading';
        }
      };
      fs.deleteDoc(fs.doc(fs.db, state.collectionName, id))
      .then(() => {
        for (let i in state.items) {
          if (state.items[i].id == id) {
            state.items.splice(i, 1);
          }
        };
        $store.dispatch('card/updateDisplayItems');
      })
      .catch(e => {
        doc.state = 'failed';
      });
    },
    async removeByCategory({state}, categoryId) {
      (await fs.getDocs(fs.query(
        fs.collection(fs.db, state.collectionName), //追加先コレクションの指定
        fs.where("category_id", "==", categoryId), //絞り込み
      )))
      .forEach(async doc => {
          await fs.deleteDoc(doc.ref); //ドキュメントの削除
      });
    },
    //しおりをはさむ
    async bookmark({state}, doc) {
      for (let i in state.items) {
        if (state.items[i].id == doc.id) {
          state.items[i].bookmark = state.items[i].bookmark ? false : true;
          $store.dispatch('card/update', state.items[i]);
        } else {
          if (state.items[i].bookmark) {
            state.items[i].bookmark = false;
            $store.dispatch('card/update', state.items[i]);
          }
        }
      }
      $store.dispatch('card/updateDisplayItems');
    },
    // itemsをdisplayIndex順に並び替える
    sortLocalItems({state}) {
      state.items.sort((a, b) => {
        return a.display_index - b.display_index;
      });
      $store.dispatch('card/updateDisplayItems');
    },
    // 最後尾のDisplayIndexを取得する
    getLastDisplayIndex({state}) {
      let index = 0;
      state.items.forEach(item => {
        index = item.display_index > index ? item.display_index : index;
      });
      return index;
    },
    // 特定のdocumentの後ろに追加する場合のIndexを取得する
    // appentAfter:afterに指定したdocumentの後ろに追加する
    getInsertDisplayIndex({state}, appendAfter) {
      let appendAfter2 = null;
      for (let i in state.items) {
        if (state.items[i].id == appendAfter.id) {
          appendAfter2 = state.items[Number(i) + 1];
          break;
        }
      }
      if (appendAfter2 == null) {
        // 最後尾のとき
        return appendAfter.display_index + 100;
      } else {
        // 2つのdocumentのdisplay_indexの平均
        return (appendAfter.display_index + appendAfter2.display_index) / 2;
      }
    }
  },
}


const $store = createStore({
  modules: {
    auth: AuthModule,
    menu: MenuModule,
    category: CategoryModule,
    card: CardModule
  }
})

export default $store;