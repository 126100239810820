<template>
  <div
    :class="{
      card: true,
      flat: flat,
      tile: tile,
      outline: outline,
      round: round,
      'base--bg': backgroundcolor === undefined,
      [`${backgroundcolor}--bg`]: true
    }"
    :style="setCssVar()"
  >
    <slot />
  </div>
</template>

<script>
/*
props
  backgroundcolor(str[color]): 背景色
  flat(bool): 影なし
  tile(bool): 角丸なし
  width, height:
  outline(bool): 縁取り（+flat)

*/
import cssVar from "./scss/Sm-style-variables.scss";
export default {
  props: {
    backgroundcolor: String,
    flat: Boolean,
    tile: Boolean,
    width: String,
    height: String,
    outline: Boolean,
    round: Boolean
  },
  methods: {
    setCssVar() {
      return {
        "--raduis": this.height || "40px",
        width: this.width,
        height: this.height
      };
    }
  }
};
</script>

<style lang="scss" scoped>
@import "./scss/Sm-style-variables.scss";
.card {
  border-radius: 8px;
  box-shadow: 0px 2px 6px -1px get-color(shadow);
}
.flat {
  box-shadow: none;
}
.tile {
  border-radius: 0px;
}
.outline {
  box-shadow: none;
  border: 1px solid get-color(outline);
}
.round {
  border-radius: var(--raduis);
}
</style>
