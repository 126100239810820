<template>
    <div>
        <div class="menu" :style="{top: `${positionY}px`, left: `${positionX - sizeX - 20}px`}">
        <sm-card class="pt-3 pb-3 d-flex-column text-align-left" :width="sizeX + 'px'" tile>
            <div v-for="(item, i) in items" :key="i" class="menu-item pa-3 text-8" :class="item.color + '--text'"
            @click="menuHandler(item.handler, $event)">
                <sm-icon :icon="item.icon"/>
                {{item.name}}
            </div>
        </sm-card>
    </div>

    </div>
</template>

<script>
export default {
    props: {
        ctx: Object,
        positionX: Number,
        positionY: Number
    },
    data() {
        return {
            items: [
                {
                    icon: 'fas fa-pen',
                    name: '名前を変更',
                    color: 'default',
                    handler: 'rename'
                },
                {
                    icon: 'fas fa-arrow-up',
                    name: 'ひとつ上に移動',
                    color: 'default',
                    handler: 'moveup'
                },
                {
                    icon: 'fas fa-arrow-down',
                    name: 'ひとつ下に移動',
                    color: 'default',
                    handler: 'movedown'
                },
                {
                    icon: 'fas fa-trash',
                    name: '削除',
                    color: 'error',
                    handler: 'remove'
                }
            ],
            sizeX: 200,
        }
    },
    methods: {
        menuHandler(handler, e) {
            if (handler == 'rename') {
                this.$emit('rename', this.ctx, e);
            } else if (handler == 'remove') {
                this.$emit('remove', this.ctx, e);
            } else if (handler == 'moveup') {
                this.$emit('moveup', this.ctx, e);
            } else if (handler == 'movedown') {
                this.$emit('movedown', this.ctx, e);
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import "./sm/scss/Sm-style-variables.scss";
.menu {
    position: fixed;
}

.menu-item{
    cursor: pointer;
    &:hover {
        background-color: get-color(focusback);
    }
}

</style>