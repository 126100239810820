<template>
    <div class="back--bg background">
        <div class="primary--bg pt-10 pb-10">
            <div class="d-flex-row align-center text-align-center justify-center pb-4">
                <img src="@/assets/service_logo.png" width="100vw" />
                <div class="text-bold base--text text-15">
                    MemoryCard
                </div>
            </div>
            <div class="base--text">
                ― コツコツ勉強しよう ―
            </div>
        </div>
        <img src="@/assets/signin_logo.png" class="pa-5" width="200vw" />
        <div class=" justify-center d-flex">
            <sm-card flat backgroundcolor="back" class="form-wrapper pa-5 pt-1">
                <sm-input v-model="mail" width="100%" height="30px" class="pa-3" type="mail" placeholder="メールアドレス"></sm-input>
                <sm-input v-model="pass" width="100%" height="30px" class="pa-3" type="password" placeholder="パスワード"></sm-input>
                <sm-button round width="100%" class="pa-3" @click="onSigninClick">
                    <div v-show="loading" class="d-flex-row text-align-center align-center justify-center text-bold">
                        <sm-loading :size="1" color="base"/>
                        ログイン中
                    </div>
                    <div v-show="!loading" class="pa-2 text-bold">
                        <sm-icon icon="fas fa-sign-in-alt"/>
                        サインイン
                    </div>
                </sm-button>
                <span class="error--text text-bold text-10" v-show="error">
                    <sm-icon icon="fas fa-exclamation-triangle"/>
                    認証に失敗しました
                </span>
            </sm-card>
        </div>
    </div>
</template>

<script>
import fs from '@/firebase';

export default {
    data() {
        return {
            mail: '',
            pass: '',
            loading: false,
            error: false
        }
    },
    methods: {
        async onSigninClick () {
            if (this.loading) return;
            this.loading = true;
            this.error = false;
            let res = await this.$store.dispatch('auth/signin', { email: this.mail, password: this.pass} );
            if (res) {
                this.$router.push('/');
            } else {
                this.error = true;
                this.loading = false;
            }
        }
    },
}
</script>

<style lang="scss" scoped>
.background {
    height: 100vh;
}
.form-wrapper {
    width: 80%;
    max-width: 400px;
}


</style>