<template>
  <div class="d-flex justify-center">
    <div class="pa-5 mt-10 mb-10 text-start card-wrapper">
      <div class="pa-3 pt-5 text-align-right justify-end align-center d-flex">
        <sm-button fab color="bookmark" flat @click="scrollToBookmark" class="pr-5 bookmark-button" width="40px" height="40px">
          <sm-icon icon="fas fa-bookmark"/>
        </sm-button>
        <span class="pr-2">
          <sm-icon icon="fas fa-check-square" color="primary"/>
          のみ表示
        </span>
        <sm-switch color="primary" v-model="checkSwitch" @update:modelValue="onCheckSwitchTrigger"/>
      </div>
      <div>
        <div v-for="(item, i) in items" :key="i" class="pa-3" :ref="item.id">
          <card-item :ctx="item" @check="onCheckTrigger" @edit="onEditTrigger" @ctxMenu="onCtxMenuTrigger"/>
        </div>
      </div>
    </div>

    <!--contextmenu-->
    <CardContextMenu v-if="onCtxMenu.visible" :positionX="onCtxMenu.positionX" :positionY="onCtxMenu.positionY" :ctx="onCtxMenu.ctx"
    @remove="onRemoveTrigger" @insert="onInsertTrigger" @moveup="onMoveupTrigger" @movedown="onMovedownTrigger" @bookmark="onBookmarkTrigger"/>

    <!--edit-->
    <CardEditErrorDialog v-model="onEdit.error" @cancel="onEdit.error=false"/>
    <CardEditDialog v-model="onEdit.edit" :front="onEdit.model.front" :back="onEdit.model.back" @cancel="onEdit.edit=false" @commit="onEditCommit"
    @changeFront="(v)=>onEdit.model.front=v" @changeBack="(v)=>onEdit.model.back=v"/>

    <!--appenButton-->
    <sm-button fab  width="50px" height="50px" class="append-button" @click="appendButtonOnClick">
        <sm-icon icon="fas fa-plus" size="20px"/>
    </sm-button>
  </div>
</template>

<script>
import CardItem from './CardItem.vue';
import CardEditDialog from './CardEditDialog.vue';
import CardContextMenu from './CardContextMenu.vue';
import CardEditErrorDialog from './CardEditErrorDialog.vue';

export default {
  data() {
    return {
      onEdit: {
        type: '',
        model: { front: '', back: '', display_index: null },
        ctx: null,
        edit: false,
        error: false
      },
      onCtxMenu: {
        visible: false,
        positionX: 0,
        positonY: 0,
        ctx: null
      },
      checkSwitch: false
    }
  },
  computed: {
    items: {
      get() {
        return this.$store.getters['card/displayItems'];
      }
    }
  },
  methods: {
    async onCheckSwitchTrigger() {
      await this.$store.dispatch('card/updateDisplayItems', this.checkSwitch);
    },
    async onCheckTrigger(ctx) {
      this.onEdit.ctx = ctx;
      this.onEdit.ctx.check = this.onEdit.ctx.check ? false : true;
      await this.$store.dispatch('card/update', this.onEdit.ctx);
      await this.$store.dispatch('card/updateDisplayItems', this.checkSwitch);
    },
    onEditTrigger(ctx) {
      this.onEdit.type = 'update';
      this.onEdit.ctx = ctx;
      this.onEdit.model.front = ctx.front;
      this.onEdit.model.back = ctx.back;
      this.onEdit.edit = true;
    },
    async onEditCommit(front, back) {
      this.onEdit.edit = false;
      if (this.onEdit.type == 'update') {
        if (this.onEdit.ctx.front == front && this.onEdit.ctx.back == back) return;
        this.onEdit.ctx.front = front;
        this.onEdit.ctx.back = back;
        await this.$store.dispatch('card/update', this.onEdit.ctx);
      } else if (this.onEdit.type == 'append') {
        // 最後尾に追加
        this.resetModel();
        this.onEdit.model.front = front;
        this.onEdit.model.back = back;
        await this.$store.dispatch('card/insert', this.onEdit.model);
      } else if (this.onEdit.type == 'insert') {
        // 挿入
        this.resetModel();
        this.onEdit.model.front = front;
        this.onEdit.model.back = back;
        this.onEdit.model.display_index = await this.$store.dispatch('card/getInsertDisplayIndex', this.onCtxMenu.ctx);
        await this.$store.dispatch('card/insert', this.onEdit.model);
      }
    },
    onCtxMenuTrigger(ctx, e) {
      if (this.onCtxMenu.visible) {
        this.hideCtxMenu(e);
        return;
      }
      // クリックの外側でクリックしたときにメニューを非表示にする
      document.addEventListener('click', this.hideCtxMenu);
      this.onCtxMenu.ctx = ctx;
      this.onCtxMenu.positionX = e.clientX;
      this.onCtxMenu.positionY = e.clientY;
      this.onCtxMenu.visible = true;
    },
    hideCtxMenu(e) {
      e.stopPropagation();
      this.onCtxMenu.visible = false;
      document.removeEventListener('click', this.hideContextMenu);
    },
    async onRemoveTrigger(ctx, e) {
      e.stopPropagation();
      this.hideCtxMenu(e);
      await this.$store.dispatch('card/remove', ctx.id);
    },
    async onBookmarkTrigger(ctx, e) {
      e.stopPropagation();
      this.hideCtxMenu(e);
      await this.$store.dispatch('card/bookmark', ctx);
    },
    async onInsertTrigger(ctx, e) {
      e.stopPropagation();
      this.hideCtxMenu(e);
      this.onEdit.type = 'insert';
      this.resetModel();
      this.onEdit.edit = true;
    },
    async onMoveupTrigger(ctx, e) {
      e.stopPropagation();
      this.hideCtxMenu(e);
      await this.$store.dispatch('card/moveDisplayIndex', { doc: ctx, direction: 'up'});
    },
    async onMovedownTrigger(ctx, e) {
      e.stopPropagation();
      this.hideCtxMenu(e);
      await this.$store.dispatch('card/moveDisplayIndex', { doc: ctx, direction: 'down'});
    },
    async appendButtonOnClick() {
      if (this.$store.state.category.selectedId == null) {
        this.onEdit.error = true;
        return;
      }
      this.onEdit.type = 'append';
      this.resetModel();
      this.onEdit.edit = true;
    },
    scrollToBookmark() {
      for (let i in this.items) {
        if (this.items[i].bookmark) {
          let el = this.$refs[this.items[i].id][0];
          el.scrollIntoView({ behavior: 'smooth' });
          break;
        }
      }
    },
    resetModel() {
      this.onEdit.model.front = '';
      this.onEdit.model.back = '';
      this.onEdit.model.display_index = null;
    }
  },
  components: {
    CardItem,
    CardEditDialog,
    CardContextMenu,
    CardEditErrorDialog
}
}

</script>

<style lang="scss" scoped>
.append-button {
    position: fixed;
    bottom: 40px;
    right: 40px;
}

.card-wrapper {
  width: 600px;
  min-height: 100vh;
}

.bookmark-button {
  margin-right: auto;
}
</style>