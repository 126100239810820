import { createRouter, createWebHistory } from 'vue-router'
import CardListView from "../views/CardListView.vue";
import SigninView from "../views/SigninView.vue";
import $store from "@/store/index.js";
import fs from "@/firebase"

const routes = [
  {
    path: "/",
    name: "CardList",
    component: CardListView,
    meta: { requireAuth: true }
  },
  {
    path: "/signin",
    name: "SignInView",
    component: SigninView,
    meta: { requireAuth: false }
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach(async (to, from, next) => {
  // ユーザー認証処理
  if (to.matched.some(record => record.meta.requireAuth)) {
    console.log(await $store.dispatch('auth/auth'))
    if (!await $store.dispatch('auth/auth')) {
      // signin
      next('/signin');
    } else {
      await fetchAppData();
      next();
    }
  } else {
    next();
  }
});

/* componentのcreatedでデータをfetchすると順番通りの処理にならないので
* 事前に必要なデータをダウンロードしてからルーティングするようにしている
*/
const fetchAppData =  async () => {
  await $store.dispatch('category/fetch');
  await $store.dispatch('card/fetch');
}

export default router
