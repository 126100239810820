<template>
    <div>
        <transition>
            <div class="overlay" v-if="isMenuOpen" @click="toggleMenu"></div>
        </transition>
        <div class="menu" :class="{'slide-in': isMenuOpen, 'slide-out': !isMenuOpen}">

            <sm-header backgroundcolor="primary" color="base" height="60px" class="d-flex row align-center justify-space-between header">
                    <sm-button fab flat outline color="primary" width="40px" height="40px" class="pl-4"
                    @click="toggleMenu">
                        <sm-icon icon="fas fa-times" size="20px"/>
                    </sm-button>
                    <div class="text-align-center pa-5 text-bold">
                        カテゴリを選択
                    </div>
                </sm-header>


            <div class="menu-background">

                <sm-card tile flat class="pt-3 pb-10 mb-5 text-start">
                    <CategoryList @categoryMenuOnClick="categoryMenuTrigger"/>
                </sm-card>

                <!--appenButton-->
                <div class="w-100">
                    <sm-button fab  width="50px" height="50px" class="append-button" @click="appendButtonOnClick($event)">
                        <sm-icon icon="fas fa-plus" size="20px"/>
                    </sm-button>
                </div>

                <!--categoryMenu-->
                <CategoryMenu v-if="categoryMenu.visible" :ctx="categoryMenu.ctx" :positionX="categoryMenu.positionX" :positionY="categoryMenu.positionY"
                @rename="onRenameTrigger" @moveup="onMoveupTrigger" @movedown="onMovedownTrigger" @remove="onRemoveTrigger"/>

            </div>

        </div>
        <DialogMenu :ctx="categoryMenu.ctx" :showType="showDialogType" @closeDialog="closeDialog"></DialogMenu>
    </div>
</template>
  
<script>
import CategoryList from './CategoryList.vue'
import CategoryMenu from './CategoryMenu.vue';
import DialogMenu from './DialogMenu.vue';

  export default {
    data() {
        return {
            categoryMenu: {
                visible: false,
                ctx: null,
                positionX: 0,
                positionY: 0
            },
            showDialogType: '',
            renameModel: '',
        }
    },
    computed: {
        isMenuOpen() {
            return this.$store.state.menu.isOpen;
        }
    },
    methods: {
        toggleMenu() {
            this.$store.commit('menu/toggle');
        },
        categoryMenuTrigger(item, e) {
            if (this.categoryMenu.visible) {
                this.hideCategoryMenu(e);
                return;
            }
            // クリックの外側でクリックしたときにメニューを非表示にする
            document.addEventListener('click', this.hideCategoryMenu);
            this.categoryMenu.ctx = item;
            this.categoryMenu.positionX = e.clientX;
            this.categoryMenu.positionY = e.clientY;
            this.categoryMenu.visible = true;
        },
        hideCategoryMenu(e) {
            e.stopPropagation();
            this.categoryMenu.visible = false;
            document.removeEventListener('click', this.hideCategoryMenu);
        },
        onRenameTrigger(ctx, e) {
            this.hideCategoryMenu(e);
            this.showDialogType = 'rename';
        },
        onMoveupTrigger(ctx, e) {
            this.$store.dispatch('category/moveDisplayIndex', {doc: ctx, direction: 'up'});
        },
        onMovedownTrigger(ctx, e) {
            this.$store.dispatch('category/moveDisplayIndex', {doc: ctx, direction: 'down'});
        },
        onRemoveTrigger(ctx, e) {
            this.hideCategoryMenu(e);
            this.showDialogType = 'remove';
        },
        closeDialog() {
            this.showDialogType = '';
        },
        appendButtonOnClick() {
            this.showDialogType = 'append';
        }
    },
    components: {
        CategoryList,
        CategoryMenu,
        DialogMenu
    }
};
  </script>
  
<style lang="scss" scoped>
@import "./sm/scss/Sm-style-variables.scss";
.overlay {
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    opacity: 0.6;
    background-color: black;
}

.v-enter-active, .v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter, .v-leave-to {
  opacity: 0;
}

/* メニューのスタイルを設定 */

.menu {
    position: fixed;
    top: 0;
    left: -100%; /* メニューを画面外に配置 */
    width: 80%; /* メニューの幅 */
    max-width: 400px;
    height: 100%;
    z-index: 2;
}

/* メニューが表示されるときのスタイル */
.menu.slide-in {
    left: 0; /* メニューを画面内にスライド */
    transition: left .5s ease; /* スライドアニメーションの設定 */
}

/* メニューが閉じられたときのスタイル */
.menu.slide-out {
    left: -100%; /* メニューを画面外にスライド */
    transition: left .5s ease; /* スライドアウトアニメーションの設定 */
}
.menu-background {
    //position: fixed;
    background-color: get-color(base); /* メニューの背景色 */
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    overflow: auto;

    &::-webkit-scrollbar {
        width: 8px;
        height: 20px;
    }
    &::-webkit-scrollbar-track {
        background-color: rgba(0, 0, 0, 0);
        border-radius: 100px;
    }
    &::-webkit-scrollbar-thumb {
        background: rgb(140, 150, 165);
        border-radius: 100px;
        width: 130px;
    }
}
.append-button {
    display: block;
    position: sticky;
    left: calc(100% - 80px);
    bottom: 80px;
}
</style>