<template>
    <div class="card-content" @click="flip">
        <sm-card v-for="(item, i) in contents" :key="i" class="pa-4 d-flex-column" ref="itemRefs" :class="{'isflipped': !showFront, 'card-front': i==0, 'card-back': i==1}"
        :style="{transition: transition}" :backgroundcolor="i==1?'card-back':undefined">
            <div>
                <div class="bookmark pl-2 pr-2 pt-1 pb-1" v-show="ctx.bookmark">
                </div>

                <div class="d-flex-column" v-if="i==0" ref="front" :style="{'height': item.height}">
                    <div class="text-bold pb-2 text-10 title-q">Question</div>
                    <div class="card-text" v-text="ctx.front"></div>
                </div>
                <div class="d-flex-column" v-if="i==1" ref="back" :style="{'height': item.height}">
                    <div class="text-bold pb-2 text-10 title-a">Answer</div>
                    <div class="card-text" v-text="ctx.back"></div>
                </div>
            </div>

            <div class="menu-container pb-1">
                <div class="d-flex-row justify-end mt-2" v-show="ctx.state=='done'">
                    <!--チェック-->
                    <sm-button flat fab :color="i==1?'card-back':'base'" @click="checkOnClick" class="mr-10 mt-2">
                        <sm-icon icon="fas fa-check-square" size="14px" :color="ctx.check ?'primary':'shadow'"/>
                    </sm-button>

                    <!--編集-->
                    <sm-button flat fab :color="i==1?'card-back':'base'" @click="editOnClick" class="mr-10 mt-2">
                        <sm-icon icon="fas fa-pen" size="14px" color="shadow"/>
                    </sm-button>

                    <!--メニュー-->
                    <sm-button flat fab :color="i==1?'card-back':'base'"  @click="contextMenuOnClick" class="mt-2">
                        <sm-icon icon="fas fa-ellipsis-v" size="14px" color="shadow"/>
                    </sm-button>
                </div>
                
                <!--なぜかloadingだけbackfacevisibilityが聞かないので、個別にopacityを設定している-->
                <div class="d-flex-row justify-end mt-2" v-show="ctx.state=='loading'" :style="{'opacity': (i==0 && showFront || i==1 && !showFront) ? 1 : 0}">
                    <sm-loading :size="1"/>
                </div>

                <div class="d-flex-row justify-end" v-show="ctx.state=='failed'">
                failed
                </div>
            </div>

        </sm-card>
    </div>
</template>

<script>
export default {
    props: {
        ctx: Object,
        resetHeight: Boolean
    },
    data() {
        return {
            showFront: true,
            height: '0px',
            isFlip: false,
        }
    },
    computed: {
        contents() {
            return [
                {
                    flip: 'front',
                    content: this.ctx.front,
                    height: ''
                },
                {
                    flip: 'back',
                    content: this.ctx.back,
                    height: ''
                },
            ];
        },
        transition: {
            get() {
                // 初期表示でサイズを調整しているが、そのときにトランジションが反映されてしまうため、フリップ時のみトランジションをかけるようにしている
                if (!this.isFlip) {
                    return '0s';
                } else {
                    return '0.5s';
                }
            }
        }
    },
    methods: {
        flip () {
            this.isFlip = true;
            if (this.onEdit) return;
            this.showFront = !this.showFront;
        },
        checkOnClick(e) {
            e.stopPropagation();
            this.$emit('check', this.ctx);
        },
        editOnClick(e) {
            e.stopPropagation();
            this.$emit('edit', this.ctx);
        },
        contextMenuOnClick(e) {
            e.stopPropagation();
            this.$emit('ctxMenu', this.ctx, e);
        },
        async remove(e) {
            e.stopPropagation();
            await this.$store.dispatch('card/remove', this.ctx.id);
        },
        setHeight() {
            let height1 = this.$refs.front[0].scrollHeight;
            let height2 = this.$refs.back[0].scrollHeight;
            let height = Math.max(height1, height2) + 'px';

            if (height == this.contents[0].height && height == this.contents[1].height) return;
            this.contents[0].height = height;
            this.contents[1].height = height;
            this.$forceUpdate();
        }
    },
    mounted() {
        this.setHeight();
    },
    updated() {
        this.setHeight();
    }
}
</script>

<style lang="scss" scoped>
@import "./sm/scss/Sm-style-variables.scss";
.card-content {
    display: block;
    position: relative;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
}

.card-front, .card-back {
    backface-visibility: hidden;
    transition: 0s;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    line-height: 175%;
}

.card-back {
    position: absolute;
    top: 0;
    left: 0;
    transform: rotateY(180deg);
}

.isflipped.card-front {
    transform: rotateY(180deg);
}
.isflipped.card-back {
    transform: rotateY(0);
}

.title-q {
    color: #a3c6ff;
}
.title-a {
    color: #a3c6ff;
}
.card-text {
    white-space: pre-line;
}
.menu-container {
    margin-top: auto;
}
.bookmark {
    position: absolute;
    right: 10px;
    top: 0px;
    width: 60px;
    height: 40px;
    background-color: get-color(bookmark);
    color: get-color(bookmark);
    font-weight: bold;

    background: conic-gradient(
        from -45deg,
        currentColor 25%,
        currentColor 50%,
        transparent 50%,
        transparent 75%,
        currentColor 75%
    ) 50% 120% / 40% 100% no-repeat;
}
</style>