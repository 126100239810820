import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'

// custom
// カスタム
import { registerComponent } from "./components/sm";

let app = createApp(App);

// カスタムコンポーネントの登録
registerComponent(app);

app.use(store).use(router).mount('#app')
