<template>
  <sm-header fixed backgroundcolor="primary" color="base" height="60px" class="d-flex row align-center justify-space-between header">
      <sm-button fab flat outline color="primary" width="40px" height="40px" class="pl-4"
      @click="toggleMenu">
        <sm-icon icon="fas fa-bars" size="20px"/>
      </sm-button>
      <div class="d-flex-row justify-center align-center pr-3">
        <img src="@/assets/service_logo.png" width="50vw" />
        <span class="text-bold text-11">MemoryCard</span>
      </div>
  </sm-header>
</template>

<script>
export default {
  methods: {
    toggleMenu() {
      this.$store.commit('menu/toggle')
    },
    async insert() {
      await this.$store.dispatch('card/insert');
    }
  }
}
</script>

<style lang="scss" scoped>
.header {
  z-index: 1;
}
</style>