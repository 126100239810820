<template>
    <div>
        <sm-dialog v-model="showDialog">
            <sm-card class="pa-5 pt-3 dialog text-align-left">
                <div class="text-bold pb-4 error--text">
                    <sm-icon icon="fas fa-pen"/>
                    カードを編集[エラー]
                </div>
                <div>
                    カードを編集するにはカテゴリを選択する必要があります
                </div>
            </sm-card>
        </sm-dialog>
    </div>
</template>

<script>
export default {
    props: {
        value: Boolean,
    },
    computed: {
        showDialog: {
            get() {
                return this.value;
            },
            set(v) {
                if (!v) this.$emit('cancel');
            }
        },
    }
}

</script>