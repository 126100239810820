<template>
    <div>
        <div :class="[`${spSize}`, `sp-circle-${ctxColor}`]"></div>
    </div>
</template>

<script>
export default {
    props: {
        size: Number,
        color: String
    },
    computed: {
        spSize() {
            return `sp-${this.size}`;
        },
        ctxColor() {
            if (!this.color) return 'primary';
            return this.color;
        }
    }
}
</script>

<style lang="scss" scoped>
@import "@/components/sm/scss/Sm-style-variables.scss";

/* Spinner Circle Rotation */
$spCol: get-color('primary');

$sizes: (
    1: 10px,
    2: 12px,
    3: 14px,
    4: 16px,
    5: 18px,
    6: 20px,
    7: 22px,
    8: 24px,
    9: 26px,
    10: 28px,
    11: 30px,
    12: 32px,
);

@each $size, $hex in $sizes {
  .sp-#{$size} {
    width: $hex;
    height: $hex;
    clear: both;
  }
};

.sp-circle {
//  border: 4px rgba($spCol,0.25) solid;
//  border-top: 4px rgba($spCol,1) solid;

    @each $color, $hex in $colors {
        &-#{$color} {
            border: 4px rgba($hex,0.25) solid;
            border-top: 4px rgba($hex,1) solid;
            border-radius: 50%;
            -webkit-animation: spCircRot .6s infinite linear;
            animation: spCircRot .6s infinite linear;
        }
    }
}
@-webkit-keyframes spCircRot {
  from { -webkit-transform: rotate(0deg); }
  to { -webkit-transform: rotate(359deg); }
}
@keyframes spCircRot {
  from { transform: rotate(0deg); }
  to { transform: rotate(359deg); }
}
</style>