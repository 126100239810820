<template>
    <div>
        <sm-dialog v-model="showDialog">
            <sm-card class="pa-5 pt-3 dialog text-align-left">
                <div class="text-bold pb-4">
                    <sm-icon icon="fas fa-pen"/>
                    カードを編集
                </div>
                <sm-textarea v-model="front_model" placeholder="Question" width="100%" height="60px" rows="6" class="mb-3"/>
                <sm-textarea v-model="back_model" placeholder="Answer" width="100%" height="60px" rows="6" class="mb-3"/>
                <div class="d-flex justify-end">
                    <sm-button flat color="primary" @click="appendOKOnClick">OK</sm-button>
                    <sm-button outline flat color="primary" class="ml-5" @click="cancelOnClick">Cancel</sm-button>
                </div>
            </sm-card>
        </sm-dialog>
    </div>
</template>

<script>
export default {
    props: {
        modelValue: Boolean,
        front: String,
        back: String
    },
    data() {
        return {
            front_ctx: '',
            back_ctx: ''
        }
    },
    computed: {
        showDialog: {
            get() {
                return this.modelValue;
            },
            set(v) {
                if (!v) this.$emit('cancel');
            }
        },
        front_model: {
            get() {
                return this.front;
            },
            set(v) {
                this.$emit('changeFront', v);
            }
        },
        back_model: {
            get() {
                return this.back;
            },
            set(v) {
                this.$emit('changeBack', v);
            }
        },
    },
    methods: {
        appendOKOnClick() {
            this.$emit('commit', this.front, this.back);
        },
        cancelOnClick() {
            this.$emit('cancel');
        },
    },
}
</script>

<style lang="scss" scoped>
.dialog {
    width: 80%;
    max-width: 400px;
}

</style>