<template>
  <div>
    <transition>
      <div class="overlay-wrapper" v-if="modelValue">
        <div :class="{ overlay: true, active: modelValue }" @click="onClick" />
        <div class="dialog">
          <slot />
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
/*
<sm-dialog v-model="showDialog">
  <sm-card>hoge</sm-card>
</sm-dialog>

v-model(bool): 表示・非表示切り替え 
props
  enableCancel: 欄外クリックで非表示可能かどうか
*/
export default {
  props: {
    modelValue: Boolean,
    enableCancel: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit("update:modelValue", v);
      }
    }
  },
  methods: {
    onClick() {
      if (this.enableCancel) {
        this.show = false;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.v-enter,
.v-leave-to {
  opacity: 0;
}

.v-leave,
.v-enter-to {
  opacity: 1;
}
.v-enter-active,
.v-leave-active {
  transition: opacity 0.3s;
}
.overlay-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 9998;
}
.overlay {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: #000000;
  opacity: 0.6;
}
.dialog {
  z-index: 9999;
  width: 100%;
  display: flex;
  justify-content: center;
}
</style>
