<template>
    <div>
        <!--Rename-->
        <sm-dialog v-model="showRenameDialog_model">
            <sm-card class="pa-5 pt-3 dialog text-align-left">
                <div class="text-bold pb-4">
                    <sm-icon icon="fas fa-pen"/>
                    名前の編集
                </div>
                <sm-input v-model="renameModel" width="100%" height="30px" class="mb-3"/>
                <div class="d-flex justify-end">
                    <sm-button flat @click="renameOKOnClick">OK</sm-button>
                    <sm-button outline flat color="primary" class="ml-5" @click="cancelOnClick">Cancel</sm-button>
                </div>
            </sm-card>
        </sm-dialog>
        <!--remove-->
        <sm-dialog v-model="showRemoveDialog_model">
            <sm-card class="pa-5 pt-3 dialog text-align-left">
                <div class="text-bold pb-4 error--text">
                    <sm-icon icon="fas fa-trash"/>
                    削除
                </div>
                <div class="mb-3">
                このカテゴリに含まれる全てのカードが削除されます。本当に良いですか？
                </div>
                <div class="d-flex justify-end">
                    <sm-button flat color="error" @click="removeOKOnClick">OK</sm-button>
                    <sm-button outline flat color="error" class="ml-5" @click="cancelOnClick">Cancel</sm-button>
                </div>
            </sm-card>
        </sm-dialog>
        <!--Append-->
        <sm-dialog v-model="showAppendDialog_model">
            <sm-card class="pa-5 pt-3 dialog text-align-left">
                <div class="text-bold pb-4">
                    <sm-icon icon="fas fa-pen"/>
                    カテゴリを作成
                </div>
                <sm-input v-model="appendModel" width="100%" height="30px" class="mb-3"/>
                <div class="d-flex justify-end">
                    <sm-button flat color="primary" @click="appendOKOnClick">OK</sm-button>
                    <sm-button outline flat color="primary" class="ml-5" @click="cancelOnClick">Cancel</sm-button>
                </div>
            </sm-card>
        </sm-dialog>
    </div>
</template>

<script>
export default {
    props: {
        showType: String,
        ctx: Object
    },
    data() {
        return {
            renameModel_entity: '',
            appendModel: ''
        }
    },
    computed: {
        showRenameDialog_model: {
            get() {
                return this.showType == 'rename';
            }
        },
        showRemoveDialog_model: {
            get() {
                return this.showType == 'remove';
            }
        },
        showAppendDialog_model: {
            get() {
                return this.showType == 'append';
            }
        },
        renameModel: {
            get() {
                if (!this.ctx) return '';
                return this.ctx.category_name
            },
            set(v) {
                this.renameModel_entity = v;
            }
        }
    },
    methods: {
        async renameOKOnClick() {
            this.$emit('closeDialog');
            if (this.renameModel_entity == '') return;
            let doc = JSON.parse(JSON.stringify(this.ctx));
            doc.name = this.renameModel_entity;
            await this.$store.dispatch('category/update', doc);
            this.$store.dispatch('category/fetch', { force: true });
            this.init();
        },
        async removeOKOnClick() {
            this.$emit('closeDialog');
            await this.$store.dispatch('category/remove', this.ctx.id);
            await this.$store.dispatch('card/removeByCategory', this.ctx.id);
            await this.$store.dispatch('category/fetch', { force: true });
            if (this.$store.state.category.selectedId == this.ctx.id) {
                // 選択中のカテゴリを削除した場合はカテゴリをセット
                // payloadに何も指定しない場合は自動的に指定される
                await this.$store.commit('category/setCategory');
                await this.$store.dispatch('card/fetch', {force: true});
            }
        },
        async appendOKOnClick() {
            this.$emit('closeDialog');
            if (this.appendModel == '') return;
            await this.$store.dispatch('category/insert', {
                name: this.appendModel
            });
            await this.$store.dispatch('category/fetch', { force: true });
            this.init();
        },
        cancelOnClick() {
            this.init();
            this.$emit('closeDialog');
        },
        init() {
            this.renameModel_entity = '';
            this.appendModel = '';
        }
    },
}
</script>

<style lang="scss" scoped>
.dialog {
    width: 80%;
    max-width: 400px;
}

</style>